import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header


    header = (
      <div>
        <h3
          style={{
            ...scale(1.5),
            marginBottom: 0,
            marginTop: 0,
            fontFamily: `Montserrat, sans-serif`,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
        <h3
          style={{
            display: 'inline-block',
            marginTop: 15
          }}
        >
          Chronique d'une reconversion</h3>
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            marginTop: 15,

          }}
        >
        </div>
        <hr></hr>
      </div>
    )

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(28),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Sven Le Cann
        </footer>
      </div>
    )
  }
}

export default Layout
